import {
  Box,
  Button,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import { useState } from 'react';
import { AlertError } from '../alerts/AlertError';
import { AlertSuccess } from '../alerts/AlertSuccess';
import ReCAPTCHA from 'react-google-recaptcha';

export const MessageModal = props => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const screenWidth = document.documentElement.clientWidth;

  const toTelegram = () => {
    const url = 'https://t.me/stigmat_dev';
    return window.open(url, '_blank');
   };

  const onChange = () => {
    setDisabled(false);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
      tel: '',
      msg: '',
    },
    onSubmit: (values, { resetForm }) => {
      let formData = new FormData();
      formData.append('name', values.name);
      formData.append('email', values.email);
      formData.append('tel', values.tel);
      formData.append('msg', values.msg);

      axios({
        method: 'POST',
        url: '../../api/send.php',
        data: formData,
      })
        .then(res => {
          console.log(res);
          setShowSuccess(true);
          setTimeout(() => {
            setShowSuccess(false);
          }, 5000);
        })
        .catch(res => {
          console.log(res);
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 5000);
        });
      resetForm({ values: '' });
    },
  });

  return (
    <Box>
      {showSuccess ? <AlertSuccess /> : showError ? <AlertError /> : null}
      <Button size={'sm'} color="#30B493" onClick={toTelegram}>
        TELEGRAM
      </Button>{' '}
      <Button size={'sm'} color="#30B493" onClick={onOpen}>
        MESSAGE ME
      </Button>
      <Modal
        size={screenWidth > 425 ? 'md' : 'full'}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send message</ModalHeader>
          <ModalBody>
            <Box p={6} rounded="md">
              <form onSubmit={handleSubmit} autoComplete="off">
                <VStack spacing={8} align="center">
                  <FormControl>
                    <Input
                      id="name"
                      placeholder="Enter name"
                      name="name"
                      type="text"
                      variant="filled"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      className={
                        errors.name && touched.name ? 'input-error' : ''
                      }
                    />
                    {errors.name && touched.name && (
                      <p className="error">{errors.name}</p>
                    )}
                  </FormControl>
                  <FormControl>
                    <Input
                      id="email"
                      placeholder="Enter email"
                      name="email"
                      type="email"
                      variant="filled"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className={
                        errors.email && touched.email ? 'input-error' : ''
                      }
                    />
                    {errors.email && touched.email && (
                      <p className="error">{errors.email}</p>
                    )}
                  </FormControl>
                  <FormControl>
                    <Input
                      id="tel"
                      placeholder="Enter phone number"
                      name="tel"
                      type="tel"
                      variant="filled"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tel}
                      className={errors.tel && touched.tel ? 'input-error' : ''}
                    />
                    {errors.tel && touched.tel && (
                      <p className="error">{errors.tel}</p>
                    )}
                  </FormControl>
                  <FormControl>
                    <Textarea
                      id="msg"
                      placeholder="Enter text of message"
                      name="msg"
                      type="text"
                      variant="filled"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.msg}
                      className={errors.msg && touched.msg ? 'input-error' : ''}
                      style={{ marginBottom: '5px' }}
                    />
                    {errors.msg && touched.msg && (
                      <p className="error">{errors.msg}</p>
                    )}
                  </FormControl>

                  <Box>
                    <ReCAPTCHA
                      sitekey="6LeUt28jAAAAAH75yBKhU3Z6268_SJlRbn2-NMyx"
                      onChange={onChange}
                    />
                  </Box>

                  <Box
                    display={'flex'}
                    w="100%"
                    justifyContent={'space-between'}
                  >
                    <Button
                      disabled={disabled}
                      id='sendMsg'
                      type="submit"
                      colorScheme="gray"
                      onClick={onClose}
                    >
                      Send message
                    </Button>

                    <Button onClick={onClose}>Close</Button>
                  </Box>
                </VStack>
              </form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
