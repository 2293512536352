import { Flex, Text } from '@chakra-ui/react';
import 'animate.css';

export const AlertError = () => {
  return (
    <Flex justifyContent="center" alignItems="center">
      <Text
        className="animate__animated animate__bounceIn"
        style={{ fontSize: '80%', color: '#eb1616', marginBottom: '20px' }}
      >
        Message not send!!!
      </Text>
    </Flex>
  );
};
