import {
  Box,
  ChakraProvider,
  extendTheme,
  Grid,
  Heading,
  Image,
  Link,
  Text,
  VStack
} from '@chakra-ui/react';
import React from 'react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { MessageModal } from './components/modals/MessageModal';
import { SkillModal } from './components/modals/SkillModal';

export const App = () => {

  const theme = extendTheme({
    config: {
      useSystemColorMode: true,
      initialColorMode: 'dark',
    },
  });
  
  return (
    <ChakraProvider theme={theme}>
      <Box boxSize="sm md" textAlign={'center'} fontSize="xl">
        <Grid minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
          <VStack spacing={8}>
            <Heading fontSize="4xl">Full-stack developer</Heading>
            <Heading fontSize="2xl">
              in{' '}
              <Link
                target={'blank'}
                color="#30B493"
                href="https://taganrog.hh.ru/resume/fba095ffff08317b100039ed1f4365376b6e51"
              >
                Russian Federation
              </Link>{' '}
            </Heading>

            <Box boxSize="sm md">
              <SkillModal />
            </Box>

            <Box boxSize="sm md">
              <Image
                src={`logo.png`}
                alt="My logo"
                borderRadius="full"
                boxShadow="3px -1px 15px 0px rgba(48, 180, 147, 0.2)"
              />
            </Box>
            <Box>
              <MessageModal />
            </Box>

            <Text>
              © Vitaliy Sheludko{' '}
              <strong style={{ color: '#30B493' }}>
                {new Date().getFullYear()}
              </strong>
            </Text>
          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );
};
