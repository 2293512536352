import { SettingsIcon } from '@chakra-ui/icons';
import {
  Box,
  Button, List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody, ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs, useDisclosure
} from '@chakra-ui/react';

export const SkillModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const screenWidth = document.documentElement.clientWidth;
  return (
    <Box>
      <Button onClick={onOpen}>SKILLS</Button>
      <Modal
        size={screenWidth > 425 ? 'md' : 'full'}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>My skills</ModalHeader>
          <ModalBody>
            <Tabs variant="unstyled">
              <TabList>
                <Tab _selected={{ color: 'white', bg: '#30B493' }}>FRONT</Tab>
                <Tab _selected={{ color: 'white', bg: '#30B493' }}>BACK</Tab>
                <Tab _selected={{ color: 'white', bg: '#30B493' }}>DB</Tab>
                <Tab _selected={{ color: 'white', bg: '#30B493' }}>Other</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <List spacing={6}>
                    <ListItem>
                      <ListIcon as={SettingsIcon} color="#30B493" />
                      JavaScript / TypeScript
                    </ListItem>
                    <ListItem>
                      <ListIcon as={SettingsIcon} color="#30B493" />
                      React.js / Vue.js
                    </ListItem>
                    <ListItem>
                      <ListIcon as={SettingsIcon} color="#30B493" />
                      Redux
                    </ListItem>
                    <ListItem>
                      <ListIcon as={SettingsIcon} color="#30B493" />
                      Redux-Saga
                    </ListItem>
                  </List>
                </TabPanel>
                <TabPanel>
                  <List spacing={6}>
                    <ListItem>
                      <ListIcon as={SettingsIcon} color="#30B493" />
                      JavaScript / TypeScript
                    </ListItem>
                    <ListItem>
                      <ListIcon as={SettingsIcon} color="#30B493" />
                      Node.js / NestJS
                    </ListItem>
                    <ListItem>
                      <ListIcon as={SettingsIcon} color="#30B493" />
                      Ruby
                    </ListItem>
                    <ListItem>
                      <ListIcon as={SettingsIcon} color="#30B493" />
                      Ruby on Rails
                    </ListItem>
                  </List>
                </TabPanel>
                <TabPanel>
                  <List spacing={6}>
                    <ListItem>
                      <ListIcon as={SettingsIcon} color="#30B493" />
                      MySQL
                    </ListItem>
                    <ListItem>
                      <ListIcon as={SettingsIcon} color="#30B493" />
                      PostgreSQL
                    </ListItem>
                    <ListItem>
                      <ListIcon as={SettingsIcon} color="#30B493" />
                      MongoDB
                    </ListItem>
                  </List>
                </TabPanel>
                <TabPanel>
                  <List spacing={6}>
                    <ListItem>
                      <ListIcon as={SettingsIcon} color="#30B493" />
                      Git
                    </ListItem>
                    <ListItem>
                      <ListIcon as={SettingsIcon} color="#30B493" />
                      Linux
                    </ListItem>
                    <ListItem>
                      <ListIcon as={SettingsIcon} color="#30B493" />
                      Cross-browser layout
                    </ListItem>
                    <ListItem>
                      <ListIcon as={SettingsIcon} color="#30B493" />
                      English A2
                    </ListItem>
                  </List>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <Button m={'auto'} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
