import { Flex, Text } from '@chakra-ui/react';

export const AlertSuccess = (props) => {
  return (
    <Flex justifyContent="center" alignItems="center">
      <Text style={{ fontSize: '80%', color: '#30B493', marginBottom: '20px' }}>
        Message send successfully!!!
      </Text>
    </Flex>
  );
};
